import { FC } from 'react'

export const ChevronUpDown: FC = () => (
  <>
    <path
      d="M11.4781 18.475L7.85313 14.85C7.80313 14.8 7.76579 14.746 7.74113 14.688C7.71646 14.63 7.70379 14.5673 7.70312 14.5C7.70312 14.3667 7.74913 14.25 7.84113 14.15C7.93313 14.05 8.05379 14 8.20312 14H15.8031C15.9531 14 16.0741 14.05 16.1661 14.15C16.2581 14.25 16.3038 14.3667 16.3031 14.5C16.3031 14.5333 16.2531 14.65 16.1531 14.85L12.5281 18.475C12.4448 18.5583 12.3615 18.6167 12.2781 18.65C12.1948 18.6833 12.1031 18.7 12.0031 18.7C11.9031 18.7 11.8115 18.6833 11.7281 18.65C11.6448 18.6167 11.5615 18.5583 11.4781 18.475Z"
      fill="currentColor"
    />
    <path
      d="M12.5282 5.22519L16.1532 8.85019C16.2032 8.90019 16.2406 8.9542 16.2652 9.0122C16.2899 9.0702 16.3026 9.13286 16.3032 9.2002C16.3032 9.33353 16.2572 9.4502 16.1652 9.5502C16.0732 9.6502 15.9526 9.7002 15.8032 9.7002L8.20322 9.70019C8.05322 9.70019 7.93222 9.65019 7.84022 9.55019C7.74822 9.45019 7.70256 9.33353 7.70322 9.20019C7.70322 9.16686 7.75322 9.05019 7.85322 8.85019L11.4782 5.22519C11.5616 5.14186 11.6449 5.08353 11.7282 5.0502C11.8116 5.01686 11.9032 5.0002 12.0032 5.0002C12.1032 5.0002 12.1949 5.01686 12.2782 5.0502C12.3616 5.08353 12.4449 5.14186 12.5282 5.22519Z"
      fill="currentColor"
    />
  </>
)
