import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { USER_STALE_TIME } from '../../config'
import {
  ApiError,
  CreateUserData,
  DisableUserData,
  ResetPasswordData,
  SendSignupEmailData,
  UpdateUserData,
} from '../../generated/users'
import { GeneratedUsersApi } from '../../GeneratedApi'
import { transformUserWithPermissions, User } from '../../models'
import { PAGINATED_USERS_QUERY_CACHE_KEY, USERS_BY_ID_QUERY_CACHE_KEY } from './useUsers'

export const useUser = ({
  userId,
  enabled = true,
}: {
  userId: string | undefined
  enabled?: boolean
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => (userId ? GeneratedUsersApi.users.getUser({ userId }) : undefined),
    enabled: !!(userId && enabled),
    staleTime: USER_STALE_TIME,
  })
  const transformedUser = useMemo(
    () => (data ? new User(transformUserWithPermissions(data)) : undefined),
    [data],
  )
  return {
    ...rest,
    data: transformedUser,
  }
}

export const useUserInfo = ({ enabled = true }: { enabled?: boolean }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['userInfo'],
    queryFn: () => GeneratedUsersApi.users.getUserInfo(),
    enabled,
    staleTime: USER_STALE_TIME,
  })
  const transformedUserInfo = useMemo(
    () => (data ? new User(transformUserWithPermissions(data)) : undefined),
    [data],
  )
  return {
    ...rest,
    data: transformedUserInfo,
  }
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()

  const { mutate: updateUser, isPending: isUpdateUserPending } = useMutation({
    mutationFn: (data: UpdateUserData) => GeneratedUsersApi.users.updateUser(data),
    onSuccess: ({ user_id: userId }) => {
      queryClient.refetchQueries({ queryKey: ['getUser', userId], type: 'active', exact: true })
      // In case the logged-in user is editing their own profile.
      queryClient.refetchQueries({ queryKey: ['userInfo'], type: 'active', exact: true })
      queryClient.invalidateQueries({
        queryKey: [PAGINATED_USERS_QUERY_CACHE_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [USERS_BY_ID_QUERY_CACHE_KEY],
      })
    },
  })
  return {
    updateUser,
    isUpdateUserPending,
  }
}

export const useCreateUser = ({ onSuccess }: { onSuccess?: (userId: string) => void }) => {
  const queryClient = useQueryClient()

  const { mutate: createUser, isPending: isCreateUserPending } = useMutation({
    mutationFn: (data: CreateUserData) => GeneratedUsersApi.users.createUser(data),
    onSuccess: ({ user_id: userId }) => {
      onSuccess?.(userId)
      queryClient.invalidateQueries({
        queryKey: [PAGINATED_USERS_QUERY_CACHE_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [USERS_BY_ID_QUERY_CACHE_KEY],
      })
    },
  })
  return {
    createUser,
    isCreateUserPending,
  }
}
export const useDeactivateUser = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient()

  const { mutate: deactivateUser, isPending: isDeactivatingUser } = useMutation({
    mutationFn: (data: DisableUserData) => GeneratedUsersApi.users.disableUser(data),
    onSuccess: () => {
      onSuccess?.()
      queryClient.invalidateQueries({
        queryKey: [PAGINATED_USERS_QUERY_CACHE_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [USERS_BY_ID_QUERY_CACHE_KEY],
      })
    },
  })
  return { deactivateUser, isDeactivatingUser }
}

export const useSendSignupEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (email: string) => void
  onError?: (error: ApiError) => void
}) => {
  const { mutate: sendSignupEmail, isPending: isSendingSignupEmail } = useMutation({
    mutationFn: (data: SendSignupEmailData) => GeneratedUsersApi.users.sendSignupEmail(data),
    onSuccess: (_, { email }) => onSuccess?.(email),
    onError: (err: ApiError) => onError?.(err),
  })
  return { sendSignupEmail, isSendingSignupEmail }
}

export const useSendPasswordResetEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (email: string) => void
  onError?: (error: ApiError) => void
}) => {
  const { mutate: sendPasswordResetEmail, isPending: isSendingPasswordResetEmail } = useMutation({
    mutationFn: (data: ResetPasswordData) => GeneratedUsersApi.users.resetPassword(data),
    onSuccess: (_, { email }) => onSuccess?.(email),
    onError: (err: ApiError) => onError?.(err),
  })
  return { sendPasswordResetEmail, isSendingPasswordResetEmail }
}
