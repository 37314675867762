import { ById } from '../utils'
import { Fact, FactState } from './fact'
import { Theme } from './theme'

export enum IngestionTaskStep {
  Extract = 'EXTRACT',
  Verify = 'VERIFY',
  QA = 'QA',
  Remediation = 'REMEDIATION',
}

export enum IngestionTaskState {
  Blocked = 'BLOCKED',
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Archived = 'ARCHIVED',
  CannotProgress = 'CANNOT_PROGRESS',
}

export type IngestionBatch = {
  id: string
  theme: Theme
  phase: number
  step: IngestionTaskStep
  totalTasks: number
  completedTasks: number
  outstandingTasks: number
  state: IngestionTaskState
  totalTimeSpent: number
  expectedTimeRemaining: number
  priority: number
}

export type IngestionBatchWithTasks = IngestionBatch & {
  tasks: IngestionTask[]
}

export type IngestionBatchesById = ById<IngestionBatch>

export type IngestionTasksById = ById<IngestionTask>

export type IngestionTask = {
  id: number
  entityId: number
  entityName?: string
  priority: number
  theme: Theme
  phase: number
  state: IngestionTaskState
  step: IngestionTaskStep
  years: number[]
  userId?: string
  username?: string
  totalTimeSpent?: number
  batchId: IngestionBatch['id']
}

export type IngestionTaskWithWorkflow = IngestionTask & {
  workflow: 'ingestion'
}

export type NextIngestionTask = {
  taskId?: IngestionTask['id']
  batchId?: IngestionBatch['id']
  noNextTask?: boolean
}

// For some reason the Omit removes the id property here, as a temporary fix, I
// have just added the id property again
// TODO: investigate why Omit removes the id property of Fact
export type IngestionTaskFact = Omit<Fact, 'status'> & {
  id: number
  entityIndustry: string
  metricName: string
  entityName: string
  state: FactState
}
