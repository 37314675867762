import { MultiFactorResolver, onAuthStateChanged, RecaptchaVerifier } from 'firebase/auth'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import {
  auth,
  AuthError,
  AuthUser,
  confirmLoginMFA as confirmLoginMFAApi,
  confirmMFAEnrollment as confirmMFAEnrollmentApi,
  login as loginApi,
  logout,
  MultifactorRequiredError,
  RecaptchaDupeError,
  RequiresRecentLoginError,
  triggerLoginMFA,
  triggerMFAEnrollment as triggerMFAEnrollmentApi,
} from '@netpurpose/api'
import { useRedirectWithOriginalUrl } from '@netpurpose/core'
import { logger } from '@netpurpose/utils'
import { config } from '#services/config'
import { isUnprotectedRoute } from '#services/routes'

type AuthState = {
  isAuthInitialised: boolean
  isAuthenticated: boolean
  authError?: AuthError | undefined
  user?: AuthUser | undefined
  mfaResolver?: MultiFactorResolver | undefined
  mfaVerificationId?: string | undefined
}

export type IAuthContext = AuthState & {
  login: (
    username: string,
    password: string,
    recaptchaVerifier?: RecaptchaVerifier,
  ) => Promise<void>
  logout: () => void
  triggerMFAEnrollment: (
    phoneNumber: string,
    recaptchaVerifier?: RecaptchaVerifier,
  ) => Promise<string | null>
  confirmMFAEnrollment: (code: string, verificationId: string) => Promise<void>
  confirmLoginMFA: (
    code: string,
    verificationId: string,
    resolver: MultiFactorResolver,
  ) => Promise<void>
  clearMFAState: () => void
}

const defaultState: AuthState = {
  isAuthInitialised: false,
  isAuthenticated: false,
}

export const defaultContext: IAuthContext = {
  ...defaultState,
  logout: () => {},
  login: async () => {},
  triggerMFAEnrollment: async () => null,
  confirmMFAEnrollment: async () => {},
  confirmLoginMFA: async () => {},
  clearMFAState: () => null,
}

export const AuthContext = React.createContext(defaultContext)

export const AuthProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [state, setState] = useState(defaultState)
  const router = useRouter()

  const onNotLoggedIn = () => {
    setState((prevState) => ({
      ...prevState,
      isAuthInitialised: true,
      isAuthenticated: false,
      user: undefined,
    }))
    if (!isUnprotectedRoute(location.pathname)) {
      // Need to use location.assign rather than router.push, as the latter
      // causes document.referrer to be lost.
      location.assign(`${location.origin}/login`)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        onNotLoggedIn()
        return
      }

      setState((prevState) => ({
        ...prevState,
        isAuthInitialised: true,
        isAuthenticated: true,
        user,
      }))
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleRedirect } = useRedirectWithOriginalUrl({
    landingPageUrl: config.routes.landingPage,
  })

  const login: IAuthContext['login'] = async (username, password, recaptchaVerifier) => {
    setState({ ...state, authError: undefined })
    try {
      await loginApi(username, password)
      handleRedirect()
    } catch (loginError) {
      if (loginError instanceof MultifactorRequiredError) {
        try {
          const { resolver } = loginError
          const verificationId = await triggerLoginMFA(resolver, recaptchaVerifier)
          setState({
            ...state,
            mfaVerificationId: verificationId,
            mfaResolver: resolver,
            authError: undefined,
          })
          return
        } catch (mfaError) {
          if (mfaError instanceof Error) {
            setState({ ...state, authError: mfaError })
            router.push(config.routes.login)
            return
          }
        }
      }
      if (loginError instanceof Error) {
        setState({ ...state, authError: loginError })
      }
    }
  }

  const triggerMFAEnrollment: IAuthContext['triggerMFAEnrollment'] = async (
    phoneNumber,
    recaptchaVerifier,
  ) => {
    setState({ ...state, authError: undefined })
    try {
      const verificationId = await triggerMFAEnrollmentApi(phoneNumber, recaptchaVerifier)
      return verificationId
    } catch (err) {
      if (err instanceof RecaptchaDupeError) {
        // Error message temporarily hidden as functionality still works
        logger.warn({ err })
        return null
      }

      if (err instanceof Error) {
        setState({ ...state, authError: err })

        if (err instanceof RequiresRecentLoginError) {
          logger.warn({ err })
          return null
        }

        logger.error({ err })
      }
      return null
    }
  }

  const confirmMFAEnrollment: IAuthContext['confirmMFAEnrollment'] = async (
    code,
    verificationId,
  ) => {
    setState({ ...state, authError: undefined })
    try {
      await confirmMFAEnrollmentApi(code, verificationId)
      router.push(config.routes.landingPage)
    } catch (err) {
      if (err instanceof Error) {
        setState({ ...state, authError: err })
        logger.error({ err })
      }
    }
  }

  const confirmLoginMFA: IAuthContext['confirmLoginMFA'] = async (
    code,
    verificationId,
    resolver,
  ) => {
    setState({ ...state, authError: undefined })
    try {
      await confirmLoginMFAApi(code, verificationId, resolver)
      router.push(config.routes.landingPage)
    } catch (err) {
      if (err instanceof Error) {
        setState({ ...state, authError: err })
        logger.error({ err })
      }
    }
  }

  const clearMFAState = () => {
    setState((prevState) => ({
      ...prevState,
      mfaVerificationId: undefined,
      mfaResolver: undefined,
      authError: undefined,
    }))
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        triggerMFAEnrollment,
        confirmMFAEnrollment,
        confirmLoginMFA,
        clearMFAState,
      }}
      {...props}
    />
  )
}

export const useAuth = (): IAuthContext => {
  const context = React.useContext(AuthContext)
  if (context === defaultContext) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
