import { User as BEUser } from '../../generated/users'
import { Camelize, snakeToCamelKeys } from '../../utils'
import { Permissions, UserType } from './user'

const transformUser = <UserProps extends Camelize<BEUser>>({
  firstName,
  lastName,
  displayName,
  type,
  defaultMetricsConfigId,
  active,
  lastLogin,
  ...rest
}: UserProps) => ({
  firstName: firstName ?? '',
  lastName: lastName ?? '',
  // NOTE: this is needed for filtering to work on users & group users tables
  combinedName: `${firstName ?? ''} ${lastName ?? ''}`,
  displayName: displayName ?? '',
  // TODO: this needs fixing on the BE, it currently returns odd types, ie., "['internal', 'external']"
  type: type as UserType,
  defaultMetricsConfigId: defaultMetricsConfigId ?? '',
  active: active ?? false,
  lastLogin: lastLogin ? new Date(lastLogin) : null,
  ...rest,
})

export const transformUserWithPermissions = (data: BEUser) => ({
  ...transformUser(snakeToCamelKeys(data)),
  // NOTE: we don't want to camelize permissions
  permissions: !!data?.permissions ? (data.permissions as Permissions) : undefined,
  groups: (data?.groups || []).map((group) => ({
    ...snakeToCamelKeys(group),
    name: group.name || '',
    permissions: !!group?.permissions ? (group.permissions as Permissions) : undefined,
  })),
})

export type UserInfo = ReturnType<typeof transformUserWithPermissions>
