import { PermissionLabels } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { AbstractApi } from '../AbstractApi'
import { UserInfo } from './parseUser'
import { Permissions, PermissionsSchema, User } from './user'

export class UsersApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getLabels = this.getLabels.bind(this)
    this.updateUserPermissions = this.updateUserPermissions.bind(this)
    this.getPermissionsSchema = this.getPermissionsSchema.bind(this)
    this.getEditablePermissions = this.getEditablePermissions.bind(this)
  }

  // NOTE: separate out methods if this grows too much, currently this is just being used
  // as a "Users service" api class.
  // These are the "/labels" api methods
  async getLabels(uri: string | undefined): Promise<PermissionLabels> {
    if (!uri) {
      throw new Error(`Cannot get labels with a url of ${uri}.`)
    }

    const {
      data: { labels },
    } = await this.api.get(`labels/${uri}`)
    return labels
  }

  // These are "/users" api methods

  async getPermissionsSchema(): Promise<PermissionsSchema> {
    const { data } = await this.api.get('/users/permissions_schema')
    return data
  }

  // These are "/users/:user_id" api methods

  async updateUserPermissions(
    updatedUserPermissions: Permissions,
    userId: User['id'] | undefined,
  ): Promise<UserInfo> {
    if (!userId) {
      throw new Error(`Cannot update user permissions with a user id of ${userId}.`)
    }
    const { data } = await this.api.put(`/users/${userId}/permissions`, updatedUserPermissions)
    return data
  }

  async getEditablePermissions(userId: User['id'] | undefined): Promise<PermissionsSchema> {
    if (!userId) {
      throw new Error(`Cannot get user editable permissions with a user id of ${userId}.`)
    }
    const { data } = await this.api.get(`/users/${userId}/editable-permissions`)
    return data
  }
}
