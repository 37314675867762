import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios'
import { getToken } from './auth'

export const attachAuthToken = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const token = await getToken()
  if (!token) {
    return config
  }
  return {
    ...config,
    headers: new AxiosHeaders({
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }),
  }
}
