// This file is for temporary use while we're mixing generated BE types with
// legacy FE types. Ideally we should just use the BE enum types in the FE.
import { pickBy } from 'lodash'
import { DatapointPolymorph, ISSB_MAJOR_CODE, Reference, Standard } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { ReportingStandardMajorDetails } from '../../../generated/facts'
import {
  Reference as BackendReference,
  ReportingStandard as BackendStandard,
  CreateReference as CreateBackendReference,
  Datapoint,
  DatapointTypes,
  MacroFactor,
  standard_class as StandardClass,
  TechnologyCost,
} from '../../../generated/modelling'
import { BackendSource, parseSource } from '../../../models'
import { snakeToCamelKeys } from '../../../utils'
import { mapDatapointStatusBEToFE, mapDatapointTypeBEToFE } from './enumMappers'

export const formatStandard = (standard: Standard): BackendStandard => ({
  reporting_standard_id: standard.id,
  standard_class: standard.standardClass as BackendStandard['standard_class'],
  ...pickBy(
    {
      major_code:
        standard.standardClass === ('ISSB' satisfies BackendStandard['standard_class'])
          ? ''
          : standard.majorCode,
      full_code: standard.fullCode,
      description: standard.description,
      link: standard.link,
      colour: standard.colour,
    },
    valueIsDefined,
  ),
})

const isBackendStandard = (
  standard: BackendStandard | ReportingStandardMajorDetails,
): standard is BackendStandard => !!(standard as BackendStandard).reporting_standard_id

export const parseReportingStandard = (
  standard: BackendStandard | ReportingStandardMajorDetails,
): Standard => ({
  id: isBackendStandard(standard) ? standard.reporting_standard_id : -1,
  standardClass: standard.standard_class,
  majorCode: standard.standard_class === StandardClass.ISSB ? ISSB_MAJOR_CODE : standard.major_code,
  fullCode: isBackendStandard(standard) ? standard.full_code : undefined,
  description: isBackendStandard(standard) ? standard.description : undefined,
  link: standard.link,
  colour: standard.colour,
})

const parseReference = (
  reference: BackendReference | CreateBackendReference,
): Partial<Reference> => ({
  ...('reference_id' in reference ? { id: reference.reference_id } : {}),
  ...(reference.source
    ? {
        source: {
          ...parseSource({
            ...(reference.source as BackendSource),
            source_id: reference.source_id,
            original_url: reference.source.original_url || '',
          }),
        },
      }
    : {}),
  ...(reference.page_num ? { pageNumber: reference.page_num } : {}),
})

const isBackendTechnologyCost = (
  datapoint: MacroFactor | TechnologyCost | Datapoint,
): datapoint is TechnologyCost => datapoint.type === DatapointTypes.TECHNOLOGY_COST

export const parseDatapointPolymorph = (
  datapoint: MacroFactor | TechnologyCost | Datapoint,
): DatapointPolymorph => {
  const { datapointId: _datapointId, ...rest } = snakeToCamelKeys(datapoint)

  return {
    ...rest,
    id: datapoint.datapoint_id || '',
    type: mapDatapointTypeBEToFE(datapoint.type),
    value: datapoint.value,
    ...(isBackendTechnologyCost(datapoint) && datapoint.technology
      ? {
          technology: {
            id: datapoint.technology.technology_id || '',
            name: datapoint.technology.technology_name,
            baseline: datapoint.technology.baseline || false,
            taxonId: datapoint.technology.taxon_id || null,
          },
        }
      : {}),
    // raw should actually always be defined, but if not probably more likely to be true.
    raw: datapoint.raw ?? true,
    status: mapDatapointStatusBEToFE(datapoint.status),
    reference: datapoint.reference ? parseReference(datapoint.reference) : undefined,
    // The equals sign gets stripped out before sending it to the BE, so when we get it back
    // we need to reinsert it so that when it gets submitted again it doesn't remove
    // the first character of the formula.
    formula: datapoint.formula && `=${datapoint.formula}`,
  }
}
