import { FactError, MetricLegacy } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { User } from '../../models'
import { joinPaths } from '../../utils'
import { AbstractApi } from '../AbstractApi'
import { parseFactError } from '../fact'

export default class ErrorApi extends AbstractApi {
  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances)
    this.getRecentErrors = this.getRecentErrors.bind(this)
  }

  async getRecentErrors({
    metricId,
    userId,
  }: {
    metricId: MetricLegacy['id'] | undefined
    userId: User['id'] | undefined
  }): Promise<FactError[]> {
    if (!metricId || !userId) {
      return Promise.reject(new Error('Missing params: metricId or userId'))
    }
    const path = joinPaths('/errors/recent', metricId, userId)
    const { data } = await this.api.get(path)
    return data.map(parseFactError)
  }
}
