import { keepPreviousData, useQuery } from '@tanstack/react-query'
import qs from 'qs'
import { useMemo } from 'react'
import { Operator } from '@netpurpose/types'
import { GeneratedUsersApi } from '../../GeneratedApi'
import { transformUserWithPermissions, UserInfo } from '../../models'
import { ReverseFieldMap } from '../../queryBuilder'
import { getPaginationConfig, useUrlApiConnector } from '../useUrlTableApiConnector'

const tableToApiFieldMap: Partial<ReverseFieldMap<keyof UserInfo>> = {
  combinedName: 'combined_name',
  email: 'email',
  type: 'type',
  groups: 'groups.name',
  lastLogin: 'last_login',
}

export const PAGINATED_USERS_QUERY_CACHE_KEY = 'paginatedUsers'
export const USERS_BY_ID_QUERY_CACHE_KEY = 'usersById'

export const usePaginatedUsers = () => {
  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<UserInfo>({
    tableToApiFieldMap,
    urlKey: PAGINATED_USERS_QUERY_CACHE_KEY,
    perPage: 10,
  })

  const { data, ...rest } = useQuery({
    queryKey: [PAGINATED_USERS_QUERY_CACHE_KEY, queryString],

    queryFn: () =>
      GeneratedUsersApi.users.getUsers({
        q: queryString,
      }),

    enabled: !!queryString,
    placeholderData: keepPreviousData,
  })
  const transformedUsers = useMemo(
    () => (data?.results ? data.results.map(transformUserWithPermissions) : undefined),
    [data],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      users: transformedUsers,
    },
    filterConfig,
    paginationConfig,
  }
}

export const useUsersByIds = ({ userIds }: { userIds: string[] | undefined }) => {
  const uniqueUserIds = userIds && Array.from(new Set(userIds))
  const combinedParams = {
    limit: 9999,
    page: 0,
    [`${Operator.Overlaps}user_id`]: uniqueUserIds,
  }
  const queryString = qs.stringify(combinedParams, {
    skipNulls: true,
    arrayFormat: 'repeat',
  })

  const { data, ...rest } = useQuery({
    queryKey: [USERS_BY_ID_QUERY_CACHE_KEY, queryString],

    queryFn: () =>
      GeneratedUsersApi.users.getUsers({
        q: queryString,
      }),

    enabled: !!userIds?.length,
  })
  const transformedUsers = useMemo(
    () => (data?.results ? data.results.map(transformUserWithPermissions) : undefined),
    [data],
  )

  return {
    ...rest,
    data: {
      users: transformedUsers,
      numResults: data?.total,
    },
  }
}

export type ReturnedUser = NonNullable<ReturnType<typeof useUsersByIds>['data']['users']>[number]
