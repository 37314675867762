import { useEffect, useState } from 'react'
import { auth, User, useUserInfo } from '@netpurpose/api'

export const useLoggedIn = (): { user?: User | undefined } => {
  const [authReady, setAuthReady] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      await auth.authStateReady()
      setAuthReady(true)
    }
    checkAuth()
  }, [])

  const authUser = auth.currentUser
  const { data: user } = useUserInfo({ enabled: Boolean(authReady && authUser) })

  if (!authUser) {
    return {}
  }

  return { user }
}
