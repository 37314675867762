import { Permissions, UserGroup } from '../../models'
import { ReverseFieldMap } from '../../queryBuilder'

export type BackendUserGroup = {
  group_id: string
  name: string
  permissions: Permissions
}

export const parseUserGroup = (backendUserGroup: BackendUserGroup): UserGroup => ({
  groupId: backendUserGroup.group_id,
  name: backendUserGroup.name,
  permissions: backendUserGroup.permissions,
})

export const reverseUserGroupFieldMap: ReverseFieldMap<keyof UserGroup> = {
  groupId: 'group_id',
  name: 'name',
  permissions: 'permissions',
}
